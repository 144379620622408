import TextCell from '@/components/Reports/Cell/TextCell.vue'

const config = {
  cellRenderer: TextCell
}

export default {
  type: 'Text',
  config
}
