import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'

const config = {
  cellRenderer: DateTimeCell
}

export default {
  type: 'DateTime',
  config
}
