<template>
  <div class="elv-table-loading">
    <el-icon v-if="params.loadingMessage === 'Loading'" class="is-loading"><Loading /></el-icon
    ><span>{{ props.params.loadingMessage }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const paddingLeft = computed(() => {
  return props.params.loadingMessage === 'Loading' ? '7px' : '15px'
})
</script>

<style lang="scss">
.elv-table-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding-left: 15px;

  span {
    padding-left: v-bind('paddingLeft');
  }
}
</style>
