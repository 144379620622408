import OperatingCell from '@/components/Reports/Cell/OperatingCell.vue'

const config = {
  cellRenderer: OperatingCell
}

export default {
  type: 'Operating',
  config
}
